import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _51ac39e5 = () => interopDefault(import('../pages/kitchensink.vue' /* webpackChunkName: "pages/kitchensink" */))
const _6a8881e9 = () => interopDefault(import('../pages/Unternehmen/Jobs/Detail/_jobId/_jobName/index.vue' /* webpackChunkName: "pages/Unternehmen/Jobs/Detail/_jobId/_jobName/index" */))
const _a607b8ea = () => interopDefault(import('../pages/Unternehmen/Jobs/Detail/_jobId/_jobName/applicationForm.vue' /* webpackChunkName: "pages/Unternehmen/Jobs/Detail/_jobId/_jobName/applicationForm" */))
const _667d39d0 = () => interopDefault(import('../pages/suche/_searchterm.vue' /* webpackChunkName: "pages/suche/_searchterm" */))
const _87d1aa58 = () => interopDefault(import('../pages/storyblok-preview/_.vue' /* webpackChunkName: "pages/storyblok-preview/_" */))
const _2df0c58d = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kitchensink",
    component: _51ac39e5,
    name: "kitchensink___de"
  }, {
    path: "/Unternehmen/Jobs/Detail/:jobId?/:jobName",
    component: _6a8881e9,
    name: "Unternehmen-Jobs-Detail-jobId-jobName___de"
  }, {
    path: "/Unternehmen/Jobs/Detail/:jobId/:jobName",
    component: _6a8881e9,
    name: "jobDetails___de"
  }, {
    path: "/Unternehmen/Jobs/Detail/:jobId?/:jobName?/applicationForm",
    component: _a607b8ea,
    name: "Unternehmen-Jobs-Detail-jobId-jobName-applicationForm___de"
  }, {
    path: "/Unternehmen/Jobs/Detail/:jobId/:jobName/bewerben",
    component: _a607b8ea,
    name: "jobApplication___de"
  }, {
    path: "/suche/:searchterm?",
    component: _667d39d0,
    name: "suche-searchterm___de"
  }, {
    path: "/storyblok-preview/*",
    component: _87d1aa58,
    name: "storyblok-preview-all___de"
  }, {
    path: "/*",
    component: _2df0c58d,
    name: "all___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
