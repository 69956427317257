export const BaseBottomBarMobile = () => import('../../components/BaseBottomBarMobile.vue' /* webpackChunkName: "components/base-bottom-bar-mobile" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseDropdownMenu = () => import('../../components/BaseDropdownMenu.vue' /* webpackChunkName: "components/base-dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseInputCheckbox = () => import('../../components/BaseInputCheckbox.vue' /* webpackChunkName: "components/base-input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseInputFile = () => import('../../components/BaseInputFile.vue' /* webpackChunkName: "components/base-input-file" */).then(c => wrapFunctional(c.default || c))
export const BaseMobileNavigationMenu = () => import('../../components/BaseMobileNavigationMenu.vue' /* webpackChunkName: "components/base-mobile-navigation-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseSearchBar = () => import('../../components/BaseSearchBar.vue' /* webpackChunkName: "components/base-search-bar" */).then(c => wrapFunctional(c.default || c))
export const BaseSearchResultContainer = () => import('../../components/BaseSearchResultContainer.vue' /* webpackChunkName: "components/base-search-result-container" */).then(c => wrapFunctional(c.default || c))
export const BaseSliderIndicator = () => import('../../components/BaseSliderIndicator.vue' /* webpackChunkName: "components/base-slider-indicator" */).then(c => wrapFunctional(c.default || c))
export const BaseSpacer = () => import('../../components/BaseSpacer.vue' /* webpackChunkName: "components/base-spacer" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/BaseTextarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const ButtonComponent = () => import('../../components/ButtonComponent.vue' /* webpackChunkName: "components/button-component" */).then(c => wrapFunctional(c.default || c))
export const ContainerWidth = () => import('../../components/ContainerWidth.vue' /* webpackChunkName: "components/container-width" */).then(c => wrapFunctional(c.default || c))
export const CounterComponent = () => import('../../components/CounterComponent.vue' /* webpackChunkName: "components/counter-component" */).then(c => wrapFunctional(c.default || c))
export const CounterComponentEntry = () => import('../../components/CounterComponentEntry.vue' /* webpackChunkName: "components/counter-component-entry" */).then(c => wrapFunctional(c.default || c))
export const EmbeddedHtml = () => import('../../components/EmbeddedHtml.vue' /* webpackChunkName: "components/embedded-html" */).then(c => wrapFunctional(c.default || c))
export const HeadlineComponent = () => import('../../components/HeadlineComponent.vue' /* webpackChunkName: "components/headline-component" */).then(c => wrapFunctional(c.default || c))
export const HistoryComponent = () => import('../../components/HistoryComponent.vue' /* webpackChunkName: "components/history-component" */).then(c => wrapFunctional(c.default || c))
export const HistoryComponentEntry = () => import('../../components/HistoryComponentEntry.vue' /* webpackChunkName: "components/history-component-entry" */).then(c => wrapFunctional(c.default || c))
export const ImageTextFullWidth = () => import('../../components/ImageTextFullWidth.vue' /* webpackChunkName: "components/image-text-full-width" */).then(c => wrapFunctional(c.default || c))
export const ImageTextHorizontalGrid = () => import('../../components/ImageTextHorizontalGrid.vue' /* webpackChunkName: "components/image-text-horizontal-grid" */).then(c => wrapFunctional(c.default || c))
export const ImageTextVertical = () => import('../../components/ImageTextVertical.vue' /* webpackChunkName: "components/image-text-vertical" */).then(c => wrapFunctional(c.default || c))
export const ImageTextVerticalColumn = () => import('../../components/ImageTextVerticalColumn.vue' /* webpackChunkName: "components/image-text-vertical-column" */).then(c => wrapFunctional(c.default || c))
export const JobDetail = () => import('../../components/JobDetail.vue' /* webpackChunkName: "components/job-detail" */).then(c => wrapFunctional(c.default || c))
export const JobPreviewComponent = () => import('../../components/JobPreviewComponent.vue' /* webpackChunkName: "components/job-preview-component" */).then(c => wrapFunctional(c.default || c))
export const LoadingCircle = () => import('../../components/LoadingCircle.vue' /* webpackChunkName: "components/loading-circle" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const OpeningHourComponent = () => import('../../components/OpeningHourComponent.vue' /* webpackChunkName: "components/opening-hour-component" */).then(c => wrapFunctional(c.default || c))
export const PictureComponent = () => import('../../components/PictureComponent.vue' /* webpackChunkName: "components/picture-component" */).then(c => wrapFunctional(c.default || c))
export const QuoteComponent = () => import('../../components/QuoteComponent.vue' /* webpackChunkName: "components/quote-component" */).then(c => wrapFunctional(c.default || c))
export const ReadMoreText = () => import('../../components/ReadMoreText.vue' /* webpackChunkName: "components/read-more-text" */).then(c => wrapFunctional(c.default || c))
export const RenderContent = () => import('../../components/RenderContent.vue' /* webpackChunkName: "components/render-content" */).then(c => wrapFunctional(c.default || c))
export const SliderTextImage = () => import('../../components/SliderTextImage.vue' /* webpackChunkName: "components/slider-text-image" */).then(c => wrapFunctional(c.default || c))
export const SliderTextImageEntry = () => import('../../components/SliderTextImageEntry.vue' /* webpackChunkName: "components/slider-text-image-entry" */).then(c => wrapFunctional(c.default || c))
export const SliderTextImageEntryFullwidth = () => import('../../components/SliderTextImageEntryFullwidth.vue' /* webpackChunkName: "components/slider-text-image-entry-fullwidth" */).then(c => wrapFunctional(c.default || c))
export const SliderVideoComponent = () => import('../../components/SliderVideoComponent.vue' /* webpackChunkName: "components/slider-video-component" */).then(c => wrapFunctional(c.default || c))
export const SocialMediaElement = () => import('../../components/SocialMediaElement.vue' /* webpackChunkName: "components/social-media-element" */).then(c => wrapFunctional(c.default || c))
export const SocialMediaElementIcon = () => import('../../components/SocialMediaElementIcon.vue' /* webpackChunkName: "components/social-media-element-icon" */).then(c => wrapFunctional(c.default || c))
export const TetrisComponent = () => import('../../components/TetrisComponent.vue' /* webpackChunkName: "components/tetris-component" */).then(c => wrapFunctional(c.default || c))
export const TetrisHeadlineComponent = () => import('../../components/TetrisHeadlineComponent.vue' /* webpackChunkName: "components/tetris-headline-component" */).then(c => wrapFunctional(c.default || c))
export const TextComponent = () => import('../../components/TextComponent.vue' /* webpackChunkName: "components/text-component" */).then(c => wrapFunctional(c.default || c))
export const TextGridText = () => import('../../components/TextGridText.vue' /* webpackChunkName: "components/text-grid-text" */).then(c => wrapFunctional(c.default || c))
export const TextOnImage = () => import('../../components/TextOnImage.vue' /* webpackChunkName: "components/text-on-image" */).then(c => wrapFunctional(c.default || c))
export const TheBackToTopButton = () => import('../../components/TheBackToTopButton.vue' /* webpackChunkName: "components/the-back-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderNavigation = () => import('../../components/TheHeaderNavigation.vue' /* webpackChunkName: "components/the-header-navigation" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
