import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_2cd249f0 from 'nuxt_plugin_plugin_2cd249f0' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_1a462420 from 'nuxt_plugin_plugin_1a462420' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_pluginutils_6989dbdd from 'nuxt_plugin_pluginutils_6989dbdd' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_36fa3430 from 'nuxt_plugin_pluginrouting_36fa3430' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7dda0e87 from 'nuxt_plugin_pluginmain_7dda0e87' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_2420f5c6 from 'nuxt_plugin_cookieuniversalnuxt_2420f5c6' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_gtm_0b23ef18 from 'nuxt_plugin_gtm_0b23ef18' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_nuxtpluginac663adc_0e691f3b from 'nuxt_plugin_nuxtpluginac663adc_0e691f3b' // Source: ./nuxt.plugin.ac663adc.js (mode: 'all')
import nuxt_plugin_nuxtplugin03ab114b_7693c5d0 from 'nuxt_plugin_nuxtplugin03ab114b_7693c5d0' // Source: ./nuxt.plugin.03ab114b.js (mode: 'server')
import nuxt_plugin_libplugin30b2a288_6b954344 from 'nuxt_plugin_libplugin30b2a288_6b954344' // Source: ./lib.plugin.30b2a288.js (mode: 'all')
import nuxt_plugin_nuxtplugin277ba552_5e61d549 from 'nuxt_plugin_nuxtplugin277ba552_5e61d549' // Source: ./nuxt.plugin.277ba552.js (mode: 'all')
import nuxt_plugin_pluginsi18ncookies1577f7e2_679834d2 from 'nuxt_plugin_pluginsi18ncookies1577f7e2_679834d2' // Source: ./plugins.i18n-cookies.1577f7e2.js (mode: 'all')
import nuxt_plugin_pluginse2etesting67ff8fde_175ab5ae from 'nuxt_plugin_pluginse2etesting67ff8fde_175ab5ae' // Source: ./plugins.e2e-testing.67ff8fde.js (mode: 'all')
import nuxt_plugin_pluginslogger32d03a72_01be6af0 from 'nuxt_plugin_pluginslogger32d03a72_01be6af0' // Source: ./plugins.logger.32d03a72.js (mode: 'all')
import nuxt_plugin_pluginsssr33cc3e7a_0c7daa74 from 'nuxt_plugin_pluginsssr33cc3e7a_0c7daa74' // Source: ./plugins.ssr.33cc3e7a.js (mode: 'all')
import nuxt_plugin_pluginscontext17eed33d_0f0a5a47 from 'nuxt_plugin_pluginscontext17eed33d_0f0a5a47' // Source: ./plugins.context.17eed33d.js (mode: 'all')
import nuxt_plugin_meta_62078502 from 'nuxt_plugin_meta_62078502' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Karriere bei Möbel Rogg","htmlAttrs":{"lang":"de"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"generator","content":"Vue Storefront 2"}],"link":[],"style":[],"script":[{"src":"https:\u002F\u002Fapp.storyblok.com\u002Ff\u002Fstoryblok-v2-latest.js"},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-PNX5HDC')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-PNX5HDC&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_2cd249f0 === 'function') {
    await nuxt_plugin_plugin_2cd249f0(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_1a462420 === 'function') {
    await nuxt_plugin_plugin_1a462420(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_6989dbdd === 'function') {
    await nuxt_plugin_pluginutils_6989dbdd(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_36fa3430 === 'function') {
    await nuxt_plugin_pluginrouting_36fa3430(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7dda0e87 === 'function') {
    await nuxt_plugin_pluginmain_7dda0e87(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_2420f5c6 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_2420f5c6(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_0b23ef18 === 'function') {
    await nuxt_plugin_gtm_0b23ef18(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtpluginac663adc_0e691f3b === 'function') {
    await nuxt_plugin_nuxtpluginac663adc_0e691f3b(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin03ab114b_7693c5d0 === 'function') {
    await nuxt_plugin_nuxtplugin03ab114b_7693c5d0(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin30b2a288_6b954344 === 'function') {
    await nuxt_plugin_libplugin30b2a288_6b954344(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtplugin277ba552_5e61d549 === 'function') {
    await nuxt_plugin_nuxtplugin277ba552_5e61d549(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsi18ncookies1577f7e2_679834d2 === 'function') {
    await nuxt_plugin_pluginsi18ncookies1577f7e2_679834d2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etesting67ff8fde_175ab5ae === 'function') {
    await nuxt_plugin_pluginse2etesting67ff8fde_175ab5ae(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginslogger32d03a72_01be6af0 === 'function') {
    await nuxt_plugin_pluginslogger32d03a72_01be6af0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsssr33cc3e7a_0c7daa74 === 'function') {
    await nuxt_plugin_pluginsssr33cc3e7a_0c7daa74(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginscontext17eed33d_0f0a5a47 === 'function') {
    await nuxt_plugin_pluginscontext17eed33d_0f0a5a47(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_62078502 === 'function') {
    await nuxt_plugin_meta_62078502(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
